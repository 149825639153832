import { create } from "zustand";
import {
  applyNodeChanges,
  applyEdgeChanges,
  NodeChange,
  EdgeChange,
} from "@xyflow/react";
import { nanoid } from "nanoid";

const initialState = { flow: {}, nodes: [], edges: [], groupByRows: [],   nodesChanges: [],
currentColors: [], isFlowLoaded: false, isLoadingFlow
: false};

export const useStore = create((set, get: any) => ({
  ...initialState,
  updateFlowLoaded(){
    set({
      isFlowLoaded: true,
    });
  },
  updateIsFlowLoading(loading: boolean){
    set({
      isLoadingFlow: loading,
    });
  },
  updateColors(colors: string[]){
    set({
      currentColors: colors,
    });
  },
  saveFlow(id: string, name: string) {
    set({
      flow: { id, name },
    });
  },
  editFlowName(name: string) {
    set({
      flow: { ...get().flow, name },
    });
  },
  onNodesChange(changes: NodeChange[]) {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  createNode(
    type: string,
    data: any,
    positionParam?: any,
    createEdgeSource?: any,
    createEdgeTarget?: any
  ) {
    const id = nanoid();
    const nodes = get().nodes;
    // Si hay nodos existentes, usa la posición del último nodo
    const position = positionParam
      ? positionParam
      : nodes.length > 0
      ? getRightPosition(nodes)
      : { x: 0, y: 0 };

    function getRightPosition(nodes: any) {
      // Obtén el último nodo en la lista
      const lastNode = nodes[nodes.length - 1];

      // Define un desplazamiento a la derecha
      const offsetX = 70;
      const offsetY = 0;

      // Calcula la nueva posición a la derecha del último nodo
      return {
        x: lastNode.position.x + offsetX,
        y: lastNode.position.y + offsetY,
      };
    }
    set({ nodes: [...nodes, { id, type, data, position }] });
    createEdgeSource && get().addEdge({ source: createEdgeSource, target: id });
    createEdgeTarget && get().addEdge({ source: id, target: createEdgeTarget });

  },
  updateNode(id: string, data: any, updateAllData?: boolean) {
    set({
      nodes: get().nodes.map((node: any) =>
        node.id === id
          ? { ...node, data: updateAllData? data : Object.assign(node.data, data) }
          : node
      ),
    });

    set({
      nodesChanges: get().nodesChanges.map((node: any) =>
        node.id === id
          ? { ...node, data: updateAllData? data : Object.assign(node.data, data) }
          : node
      ),
    });
  },
  updateNodePosition(id: string, position: object) {
    set({
      nodes: get().nodes.map((node: any) =>
        node.id === id
          ? { ...node, position: position }
          : node
      ),
    });
    set({
      nodesChanges: get().nodesChanges.map((node: any) =>
        node.id === id
          ? { ...node, position: position }
          : node
      ),
    });
  },
  onEdgesChange(changes: EdgeChange[]) {
    if (changes[0]?.type === "remove") {
      changes.forEach((change: any) => {
        const edge = get().edges.find((edge: any) => {
          return edge.id === change.id;
        });
        const edgeTargetNode = get().nodes.find((node: any) => {
          return node.id === edge.target;
        });
        get().updateNode(edgeTargetNode.id, {
          ...edgeTargetNode.data,
          parentIds: edgeTargetNode.data.parentIds?.filter(
            (parentId: string) => parentId != edge.source
          ),
        });
        return;
      });
    }
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  addEdge(data: any) {
    const connectedToNode = get().nodes.find((node: any) => {
      return node.id === data.target;
    });
    console.log(connectedToNode, "connectedToNode")
    console.log(data, "data")


    get().updateNode(connectedToNode.id, {
      ...connectedToNode.data,
      parentIds:
        connectedToNode.data.parentIds &&
        connectedToNode.data.parentIds.length > 0
          ? [ data.source, ...connectedToNode.data.parentIds]
          : [data.source],
    });
    const id = nanoid(6);
    const edge = { id, type: "custom-edge", ...data };
    set({ edges: [edge, ...get().edges] });
  },
  setInitialNodes(backendNodes: any[]) {
    set({
      nodes: backendNodes,
    });
  },
  setInitialEdges(backendEdges: any[]) {
    set({
      edges: backendEdges,
    });
  },
  updateGroupByRows(data: any[]) {
    set({
      groupByRows: data,
    });
  },
  updateSelectionNode(id: string, selected: boolean) {
    set({
      nodes: get().nodes.map((node: any) =>
        node.id === id ? { ...node, selected: selected } : node
      ),
    });
  },
  reset() {
    set(initialState);
  },
}));
